module directives {
    export module tariff {
        interface IProhibitedRestrictedScope extends ng.IScope {
            loadControls();
            gvwProhibitedAndRestricted: uiGrid.IGridOptions | any;
            apiProhibitedAndRestrictedList: uiGrid.IGridApi;
            ListFilterChange(gridApi: uiGrid.IGridApi),
            DoSearch();
            search_click();
            loadCountries(searchText: string);
            countryChanged(countryModel: interfaces.applicationcore.IDropdownModel);
            TypeList: Array<interfaces.applicationcore.IDropdownModel>;
            AgencyList: Array<interfaces.applicationcore.IDropdownModel>;
            searchAccordian: boolean;
            allowSelect: boolean;
            showGrid: boolean;
            selectedRestriction: interfaces.tariff.IProductRestrictionDetail;
            showRestrictionDetail(restrictionId: number);
            displayNotes(notes: string);
            proResSearch: interfaces.tariff.IProhibitedAndRestrictedSearch;
            getFilters();
            setFilters();
            loadAgencies();
        }

        export class prohibitedRestrictedDirective implements ng.IDirective {
            templateUrl = 'templates/modules/applicationmain/tariff/prohibitedRestrictedView.html';
            scope = {
                allowSelect: "=",
                selectedRestriction: "=?",
                proResSearch: "=?"
            }

            messages: interfaces.applicationcore.IMessage[];

            ProhibitedAndRestrictedList: Array<interfaces.tariff.IProhibitedAndRestricted> = [];
          
            paginationOptions = {
                pageNumber: 1,
                pageSize: 25
            };
 
            dropdownsObject = {
                TypeList: Array<interfaces.applicationcore.IDropdownModel>()
            };

            filterNames: string[] = ['Detail','CountryCode','RestrictionCode','Description','RestrictionType','QuotaType', 'CountryGroup','Agency','IsProhibited','StartDate','EndDate'];
            filterList: interfaces.applicationcore.IKeyValue[] = [];

            sortName: string;
            sortDirection: string;

            constructor(            
                private $q: ng.IQService,
                public $timeout: ng.ITimeoutService,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                public $uibModal: ng.ui.bootstrap.IModalService,
                private hsCodeService: interfaces.tariff.IHsCodeService,
                private $state: ng.ui.IStateService,
                private bsLoadingOverlayService,
                private menuService: interfaces.applicationcore.IMenuService,
                public productTariffService: services.tariff.productTariffService,
                private prohibitedAndRestrictedService: interfaces.tariff.IProhibitedAndRestrictedService,
                private countryService: interfaces.applicationcore.ICountryService,
                private tariffCountryService: interfaces.tariff.ITariffCountryService,
                private $transitions: ng.ui.core.ITransition,
            ) {
            }

            count: number = 0;
            link = ($scope: IProhibitedRestrictedScope, $element: ng.IAugmentedJQuery, $state, $q: ng.IQService) => {
            $scope.showGrid = false;

                $scope.loadControls = () => {
                    if (!$scope.proResSearch)
                    {
                        $scope.proResSearch = {
                            SimulationDate: moment(),
                            TypeId: -1,
                        };
            
                    }

                    this.prohibitedAndRestrictedService.getTypesDropdown().query((result: Array<interfaces.applicationcore.IDropdownModel>) => {
                        $scope.TypeList = result;
                     }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    })

                    this.countryService.GetCurrentCountry().get({  }, (result: interfaces.applicationcore.ICountry) => {
                        if (!$scope.proResSearch.Country){
                            $scope.proResSearch.Country = <interfaces.applicationcore.IDropdownModel>{
                                Id: result.Id,
                                Code: result.Code,
                                Description: result.Description,
                                Display: result.Code + " - " + result.Description,
                                Selected: true
                            };
                        }

                       $scope.loadAgencies();

                      
                        
                       $scope.DoSearch(); 

                    });

                }

                $scope.loadControls();

                $scope.search_click = () => {
                    $scope.DoSearch();
                }

                $scope.getFilters = () => {

                    _.forEach(this.filterNames, (name: string) => {
                    if ($scope.apiProhibitedAndRestrictedList.grid.getColumn(name).filters[0].term || $scope.apiProhibitedAndRestrictedList.grid.getColumn(name).filters[0].term == "")
                    {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', name));

                        if (keyValue)
                        {
                            var index = _.findIndex(this.filterList, (o) => { return o.key === name });

                            this.filterList[index] = <interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: $scope.apiProhibitedAndRestrictedList.grid.getColumn(name).filters[0].term
                            };
                        }
                        else
                        {
                            this.filterList.push(<interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: $scope.apiProhibitedAndRestrictedList.grid.getColumn(name).filters[0].term
                            });
                        }
                    }
                });

                }

                $scope.setFilters = () => {
                    if (this.filterList)
                    {
                        _.forEach(this.filterList, (keyValue: interfaces.applicationcore.IKeyValue) => {
                            $scope.apiProhibitedAndRestrictedList.grid.getColumn(keyValue.key).filters[0].term = keyValue.value;
                        });
                    }
    
                    if (this.sortName && this.sortDirection) {
                        var column = $scope.apiProhibitedAndRestrictedList.grid.getColumn(this.sortName);
    
                        if (column.sort.direction != this.sortDirection)
                        {
                            $scope.apiProhibitedAndRestrictedList.grid.sortColumn(column, this.sortDirection);
                        }
                    }
                }

                $scope.countryChanged = (countryModel: interfaces.applicationcore.IDropdownModel) => {
                   
                    $scope.proResSearch.Country = countryModel;
                    $scope.loadAgencies();
                }

                $scope.loadAgencies = () => {                

                    var defered = this.$q.defer();

                    this.prohibitedAndRestrictedService.getAgencyDropdown($scope.proResSearch.Country.Id).query({
                        
                    }, (data: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        defered.resolve(data);
                    }, () => {
                        defered.resolve([]);
                    });
    
                    return defered.promise;
                }


                $scope.loadCountries = () => {
                    var defered = this.$q.defer();

                    this.tariffCountryService.getDropdownList().query({
                        
                    }, (data: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        defered.resolve(data);
                    }, () => {
                        defered.resolve([]);
                    });
    
                    return defered.promise;
                }

                $scope.showRestrictionDetail = (restrictionId: number) => {
                    this.$state.go("auth.ProhibitedAndRestricted.RestrictionDetail", { parId: restrictionId });
                }

                $scope.displayNotes = (notes: string) => {
                 
                    this.$uibModal.open({
                        animation: true,
                        template: `
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h3 class="modal-title" id="modal-title">Notes</h3>
                                </div>
                                <div class="modal-body" id="modal-body">
                                    <p ng-bind-html="Notes"></p>
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-primary" type="button" ng-click="ok()">OK</button>
                                </div>
                            </div>
                        `,
                        controller: ($scope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) => {
                            $scope.Notes = notes;
    
                            $scope.ok = () => {
                                $uibModalInstance.dismiss();
                            }
                        },
                        size: "lg",
                        resolve: {
                        }
                    });
                }

                $scope.DoSearch = () => {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'prohib'
                    },
                        () => {
                            var restrictionSearch: interfaces.tariff.restrictionSearch = {
                                tariffCountryId : $scope.proResSearch.Country.Id,
                                simulationDate : $scope.proResSearch.SimulationDate,
                                agencyId: $scope.proResSearch.Agency? $scope.proResSearch.Agency.Id:null,
                                typeId: $scope.proResSearch.TypeId,
                                tariffCode: $scope.proResSearch.TariffCode,
                                description: $scope.proResSearch.Description
                            }

                            var params = {
                                search : restrictionSearch
                            }
    
                            return this.prohibitedAndRestrictedService.GetPublishedRestrictions().query(restrictionSearch, (result: Array<interfaces.tariff.IProhibitedAndRestricted>) => {
                                var currentDirtyRows: Array<interfaces.tariff.IProhibitedAndRestricted> = [];
    
                                _.forEach(this.ProhibitedAndRestrictedList, function (value) {
                                    var row: interfaces.tariff.IProhibitedAndRestricted = _.find(result, _.matchesProperty('Id', value.RestrictionId));
    
                                    if (row) {
                                        var i: number = _.indexOf(result, row);
                                        result[i] = value;
                                        currentDirtyRows.push(result[i]);
                                    }
                                });
    
                                $scope.gvwProhibitedAndRestricted.data = result;
                                $scope.showGrid = true;

                                this.$timeout(() => {
                                    $scope.getFilters();
                                    $scope.setFilters();
                                });
                              
    
                                if (currentDirtyRows.length > 0) {
                                    this.$timeout(() => {
                                        _.forEach(currentDirtyRows, (value) => {
                                            $scope.apiProhibitedAndRestrictedList.rowEdit.setRowsDirty(currentDirtyRows);
                                        });
                                    });
                                }
    
                                if (result[0]) {
                                    $scope.gvwProhibitedAndRestricted.totalItems = result[0].NumberRecords;
                                } else {
                                    $scope.gvwProhibitedAndRestricted.totalItems = 0;
                                }
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });
                }

             
    
                
                $scope.gvwProhibitedAndRestricted = {
                    data: [],
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    multiSelect: false,
                    enableColumnResizing: true,
                    enableFullRowSelection:  $scope.allowSelect,
                    enableRowHeaderSelection: $scope.allowSelect,
                    enableRowSelection:   $scope.allowSelect,                    
                    enableHorizontalScrollbar: 2,
                    rowEditWaitInterval: -1,
                    cellEditableCondition: true,
                    showGridFooter: false,
                    paginationPageSizes: [25, 50, 75, 100],
                    paginationPageSize: 25,
                    useExternalPagination: false,
                    enableFiltering: true,
                    useExternalFiltering: false,
                    enableSorting: true,
                    useExternalSorting: false,
                    //Excel
                    enableGridMenu: true,
                    exporterMenuCsv: false,
                    exporterMenuPdf: false,
                    exporterExcelFilename: 'ProhibitedAndRestrictedList.xlsx',
                    gridMenuShowHideColumns: false,
                    exporterSuppressColumns: ['Detail'],
                    exporterFieldCallback: (grid, row, col, value) => {
                        return this.generalService.handleValue(value);
                    },
                    onRegisterApi: (gridApi) => {
                        $scope.apiProhibitedAndRestrictedList = gridApi;
                        /*$scope.apiProhibitedAndRestrictedList.cellNav.on.navigate($scope, (gridSelection) => {
                            $scope.apiProhibitedAndRestrictedList.selection.selectRow(gridSelection.row.entity);
                          });*/

                          this.$timeout(() => {
                            if ($scope.apiProhibitedAndRestrictedList) {
                                $scope.showGrid = true;
                                $scope.DoSearch();                          
                            }
                        });  

                        $scope.apiProhibitedAndRestrictedList.selection.on.rowSelectionChanged($scope, (row) => {
                            if (row.isSelected) {
                                var  Restriction : interfaces.applicationcore.IDropdownModel = <interfaces.applicationcore.IDropdownModel>{
                                    Id: row.entity.RestrictionId,
                                    Code: row.entity.RestrictionCode,
                                    Description: row.entity.Description,
                                    Display: row.entity.RestrictionCode + " - " +  row.entity.Description
                                }
                                
                                var saveItem : interfaces.tariff.IProductRestrictionDetail = <interfaces.tariff.IProductRestrictionDetail>{
                                    Id: 0,
                                    Restriction : Restriction,
                                    IsProhibited: false,
                                    RestrictionStartDate : row.entity.StartDate,
                                    RestrictionEndDate : row.entity.EndDate                           
                            }

                            $scope.selectedRestriction = saveItem;
                        } else {
                            $scope.selectedRestriction = null;
                        }
                        });
                        $scope.apiProhibitedAndRestrictedList.pagination.on.paginationChanged($scope, (newPage, pageSize) => { this.paginationOptions.pageNumber = newPage;
                            this.paginationOptions.pageSize = pageSize; /* $scope.DoSearch();*/  });

                        $scope.apiProhibitedAndRestrictedList.core.on.filterChanged($scope, (gridApi) => {  

                                _.forEach(gridApi.grid.columns, (c) => {
                                    if(!c.filters[0].term) {
                                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', c.field));
                
                                        if(keyValue) {
                                            this.filterList.splice(this.filterList.indexOf(keyValue), 1);
                                        }
                                    }
                                });
                                
                                /*$scope.DoSearch();*/ });

                            $scope.apiProhibitedAndRestrictedList.core.on.sortChanged($scope, (grid, sortColumns) => {
                            /*$scope.DoSearch();*/
                          });
                   },
                    columnDefs: [
                    {
                        name: "Detail",
                        displayName: " ",
                        field: "Detail",
                        enableFiltering: false,
                        enableSorting: false,
                        cellTemplate: `
                        <div class="GridButton">
                            <center>
                                <button ng-if="row.entity.Notes" type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.showRestrictionDetail(row.entity.RestrictionId)">
                                    <span class="fa fa-eye"></span>
                                </button>
                            </center>
                        </div>`,
                        width: 40,
                        visible: !$scope.allowSelect
                    }, {
                        name: 'CountryCode',
                        displayName: 'Country',
                        field: 'CountryCode',
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                        width: 100,
                        enableFiltering: false,
                        enableSorting: false
                    }, {
                        name: 'RestrictionCode',
                        displayName: 'Code',
                        field: 'RestrictionCode',
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        width: 100,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`
                    }, {
                        name: 'Description',
                        displayName: 'Description',
                        field: 'Description',
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        width: 300,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`
                    }, {
                        name: 'RestrictionType',
                        displayName: 'Type',
                        field: 'RestrictionType',
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                        width: 100,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`
                    }, {
                        name: 'QuotaType',
                        displayName: 'Quota Type',
                        field: 'QuotaType',
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        width: 160,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`
                    }, {
                        name: 'CountryGroup',
                        displayName: 'Group Country',
                        field: 'CountryGroup',
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                        width: 115,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`
                    }, {
                        name: 'Agency',
                        displayName: 'Agency',
                        field: 'Agency',
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        width: 260,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`
                    }, {
                        name: 'IsProhibited',
                        displayName: 'Prohibited',
                        field: 'IsProhibited',
                        cellTemplate: `
                            <div class="ui-grid-cell-contents" ng-if="MODEL_COL_FIELD">Prohibited</div>
                            <div class="ui-grid-cell-contents" ng-if="!MODEL_COL_FIELD">Restricted</div>
                        `,
                        width: 100,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="checkbox" ng-model="colFilter.term"></div>
                                </div>`
                    }, {
                        name: 'StartDate',
                        displayName: 'Start Date',
                        field: 'StartDate',
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
                        width: 100,
                        type: "date",
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="startDate"></gts-date>
                            </div>`,
                    }, {
                        name: 'EndDate',
                        displayName: 'End Date',
                        field: 'EndDate',
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
                        width: 100,
                        type: "date",
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="endDate"></gts-date>
                            </div>`,
                    }, {
                        name: "Notes",
                        displayName: "Notes",
                        field: "Notes",
                        enableFiltering: false,
                        enableSorting: false,
                        cellTemplate: `
                            <div class="GridButton">
                                <center>
                                    <button ng-if="row.entity.Notes" type="button" class="btn btn-info btn-sm" ng-click="grid.appScope.displayNotes(grid.getCellValue(row, col))">
                                        <span class="fa fa-sticky-note"></span>
                                    </button>
                                </center>
                            </div>`, 
                        width: 70
                    } ]
                };
            }

            static factory(): ng.IDirectiveFactory {
                const directive = (
                    $q,
                    $timeout,
                    $rootScope,
                     generalService, 
                    $uibModal, 
                    hsCodeService, 
                    $state,
                    bsLoadingOverlayService, 
                    menuService,
                    productTariffService,
                    prohibitedAndRestrictedService,
                    countryService,
                    tariffCountryService,
                    $transitions) => new prohibitedRestrictedDirective( 
                        $q,
                        $timeout,
                        $rootScope,
                        generalService, 
                        $uibModal, 
                        hsCodeService, 
                        $state, 
                        bsLoadingOverlayService, 
                        menuService,
                        productTariffService,
                        prohibitedAndRestrictedService,
                        countryService,
                        tariffCountryService,
                        $transitions);
                directive.$inject = [  
                '$q',
                '$timeout',
                "$rootScope",
                "generalService", 
                "$uibModal", 
                "hsCodeService", 
                "$state", 
                "bsLoadingOverlayService", 
                "menuService",
                "productTariffService",
                "prohibitedAndRestrictedService",
                "countryService",
                "tariffCountryService",
                '$transitions' ];

                return directive;
            }
        }

        angular.module("app").directive("gtsProhibitedRestricted", prohibitedRestrictedDirective.factory());
    }
}